@import "../../../global";

.Navbar {
  padding-right: 20px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;

  // background-color: #0D0D1C;
  background-color: $secondBgColor;

  // border-bottom: 1px solid lightgray;

  @media (max-width: 768px) {
    padding: 12px 10px;
    height: 50px;
  }

  .logo {
    text-align: center;
    transition: 0.3s;
    // min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.notCollapsed {
        padding: 25px 12%;

        .name,
        .profileButton {
            visibility: hidden;
        }
    }

    img {
        // height: 60px;
        // max-height: 80px;
        // max-width: 80px;
        // border-radius: 100%;
        // width: 100%;
        cursor: pointer;
        aspect-ratio: 2;

        &.imageNotCollapsed {
            max-width: 120px;
            max-height: 120px;
            margin-left: 100px;
            margin-right: 100px;
        }
    }
}

  .titleContainer {
    padding: 0 15px;
    // margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;

    span.toggleButton {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 0 20px 0 0;
      font-size: 20px;

      // @media (min-width:991.68px) {
      //     display: none;
      // }

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    h3.title {
      font-size: 22px;
      font-weight: 700;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .date {
    margin-right: auto;
    display: flex;
    font-size: 1.4rem;
    color: gray;
    justify-content: center;
    align-items: center;

    p {
      margin: 0 20px;
      font-size: 1rem;
    }
  }

  .search {
    display: flex;
    flex-direction: row;
    width: 20rem;
    height: auto;
    min-height: 30px;
    padding: 5px 10px;

    // background-color: #181829;
    background-color: $mainBgColor;
    border-radius: 10px;
    justify-content: center;
    align-items: center;

    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      padding: 0 10px;
      color: gray;
      font-size: 16px;
      border: none;
      outline: none;
    }

    .searchButton {
      width: 35px;
      height: 30px;
      border-radius: 100%;

      // background-image: linear-gradient(to right, #B52929, #ED1B1B);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
      font-size: 1.3rem;
    }
  }

  .notificationButton {
    width: 35px;
    height: 80%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    font-size: 1.3rem;
    background-color: #181829;
    padding: 5px;
    margin: 5px 5px;
  }

  .navbaricons {
    justify-content: center;
    align-items: center;
    gap: 10px;
    img {
      width: 34px !important;
      height: 34px !important;
    }
    .profileDetail {
        text-decoration: none;
        display: flex;
        justify-content: center;
        gap: 10px;
        height: 34px;
        align-items: center;
        cursor: pointer;
        img {
            width: 22px!important;
            height: 22px!important;
        }
      a {
        text-decoration: none;
        display: flex;
        justify-content: center;
        gap: 10px;
        height: 34px;
        align-items: center;
        .name {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          // color: #343434;
        }
      }
    }
  }

  .notiIcon {
    position: relative;
    .notificationPanel {
      position: absolute;
      width: 403px;
      // background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      padding: 10px;
      z-index: 100;
      top: 50px;
      right: 0;
      padding: 20px;
      background: #ffff;
      color: black;
      @media screen and (max-width: 530px) {
        width: 303px;
        right: -50px;
      }

      .notificationHeader {
        border-bottom: 2px solid #e2e8f0;
        padding: 10px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
        //   font-family: "Gilroy";
          font-style: normal;
          font-weight: bolder;
          font-size: 16px;
          line-height: 160%;
          letter-spacing: 1.2px;
          margin: 0;
          padding: 0;
          color: #282828;
        }
        .closeBtn {
          border-radius: 50px;
          padding: 2px 5px;
          background: transparent;
          border: none;
          color: #282828;
        }
      }

      .notificationHeaderContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
      }

      .tabs {
        display: flex;
        gap: 10px;
      }

      .tab {
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.2px;
        flex: none;
        order: 0;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        gap: 10px;
        border-radius: 23px;
      }

      .tab.active {
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.2px;
        color: #2563eb;
        flex: none;
        order: 0;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 12px;
        gap: 10px;
        border-radius: 23px;
      }

      .notificationContent {
        max-height: 400px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .content {
          .day {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 160%;
            letter-spacing: 1px;
            color: #94a3b8;
          }
          .notificationItem {
            width: 100%;
            box-sizing: border-box;
            border-radius: 12px;
            padding: 10px 20px;
            gap: 20px;
            background: #f6f8fb;
            border: 1px solid #f8fafc;

            .notificationAvatar {
              img {
                width: 50px !important;
                height: 50px !important;
              }
            }
            .details {
              h3 {
                font-family: "Gilroy-ExtraBold";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 160%;
                letter-spacing: 0.2px;
                color: #282828;
              }
              p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 160%;
                color: #94a3b8;
                margin: 0;
                padding: 0;
              }
            }
            .timeSec {
              flex-direction: column;
              gap: 24px;

              .time {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 160%;
                color: #94a3b8;
              }
              .unreadDot {
                width: 8px;
                height: 8px;
                background-color: #3f72af;
                border-radius: 50%;
              }
            }
          }
        }
      }
      &.dark-mode {
        background: #18191a;
        .notification-header p {
          color: white;
        }
        .notification-header button {
          color: white;
        }
        .tab.active {
          background: #282828;
        }
        .tab {
          background: #404040;
          color: #94a3b8;
        }
        .notification-item {
          background: #404040;
          border: 1px solid #3e3e3e;
          .details h3 {
            color: white;
          }
        }
      }
      &.light-mode {
        .notification-header p {
          color: #282828;
        }
        .notification-header button {
          color: #282828;
        }
        .notification-item {
          background: #f6f8fb;
          border: 1px solid #f8fafc;
          .details h3 {
            color: #282828;
          }
        }
      }
    }
  }
}
