// Variables for colors
$primary-color: #0078d4;
$background-color: #f0f2f5;
$text-color: #555;
$white: #fff;


.VoiceCall {
    width: 100%;
    min-height: 100dvh;
    padding: 3rem 0;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:#fdfdfd ;
    color: #2C2C34;
    

    // Main Section
    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;



        .participants {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .localUser {
                overflow: unset;
                background-color: transparent !important;

                div {
                    position: unset !important;
                    overflow: unset !important;
                }

                .participant {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    .avatar {
                        width: 130px;
                        height: 130px;
                        border-radius: 50%;
                        background-color: #ccc;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 3.2rem;
                        color: $white;
                        margin-bottom: 10px;
                    }

                    .name {
                        font-size: 1.4rem;
                        font-weight: bold;
                    }

                    .audio-status {
                        margin-top: 5px;
                        font-size: 1rem;
                    }
                }
            }
        }

    }


    // Control Bar
    .controlBar {
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        gap: 20px;

        button {
            background-color: white;
            border: 1px solid gray;
            border: none;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: 18px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            background-color: #0485BB;

            &:hover {
                background-color: #0485BBb0;
            }

            &.endCall {
                background-color: #d9534f;

                &:hover {
                    background-color: #d9544fb0;
                }

            }
        }
    }

}