@import "../../../global";

.ProtectedLayout {
    display: flex;
    flex-direction: row;
    min-height: 100dvh;
    width: 100%;
    background-color:  $mainBgColor;
    color: black;

    .background {
        display: none;

        @media (max-width: 991px) {
            display: block;
            position: fixed;
            z-index: 9;
            width: 100%;
            height: 100dvh;
            top: 0;
            left: 0;

            // background-color: $mainBgColor;
        }
    }

    .menu {
        height: 100dvh;
        display: flex;
        flex-direction: column;
        overflow: overlay;

        // padding: 0 15px;
        // background-color: #0D0D1C;
        background-color: $secondBgColor;
        transition: 0.3s;
        color: black;

        @media (max-width: 991px) {
            position: absolute;
            left: -300px;
            z-index: 10;
        }

        &.collapsed {
            width: 300px;
            left: 0;
        }

        &.notCollapsed {
            width: 0px;
            align-items: center;
        }

        .drawer {
            display: none;
            text-align: center;
            width: 100%;
            height: 20px;
            margin: 25px auto 50px;

            span {
                cursor: pointer;
                background-color: transparent;
                font-size: 25px;
            }

            &.collapsed {
                text-align: right;
            }

            @media (max-width: 991px) {
                display: block;
            }
        }

        .logo {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            padding: 25px 12%;

            // min-height: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &.notCollapsed {
                padding: 25px 12%;

                .name,
                .profileButton {
                    visibility: hidden;
                }
            }

            img {
                // height: 60px;
                // max-height: 80px;
                // max-width: 80px;
                // border-radius: 100%;
                // width: 100%;
                cursor: pointer;
                aspect-ratio: 2;

                &.imageNotCollapsed {
                    max-width: 60px;
                    max-height: 60px;
                }
            }

            .name {
                width: 100%;
                font-size: 16px;
                margin: 8px 0;
                font-weight: 400;
                display: -webkit-box;
                // -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;

                // white-space: nowrap;
           
            }

            .profileButton {
                padding: 8px 25px;
                white-space: nowrap;
                color: white;
                border-radius: 5px;
                background-color: #181829;
                font-size: 12px;
                border: none;

                &:focus-visible,
                &:focus-within {
                    outline: none;
                }
            }

            // @media (max-width : 991px) {
            //     display: none;
            // }
       
        }

        .item {
            width: 100%;
            height: 60px;
            margin: 2px 0;
            padding: 18px 24px;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            color: black;

            font-weight: 500;
            font-size: 20px;

            &.active {
                // background-color: #181829;
                // background-color: $mainBgColor;
                background-color: $primaryColorWithAlpha;

                // border-radius: 15px;
                // color: white;
                color: $primaryColor;
                font-weight: 500;

                // border-right: 5px solid #0AA7FF;
                border-right: 5px solid $primaryColor;
                border-radius: 8px;
            }

            p {
                margin: 0 20px;
                white-space: nowrap;
            }
        }
    }

    .routeContent {
        position: relative;
        width: 100%;

        background-color: $mainBgColor; // todo:
        display: flex;
        flex-direction: column;
        height: 100dvh;
        overflow: hidden;
        .sideviewContent {
          width: 100%;
          height: -webkit-fill-available;
          display: flex;
          overflow: auto;
        }

        .content {
            width: 100%;
            height: -webkit-fill-available;

            // display: flex;
            overflow: auto;
            padding: 20px;

            .nav-bar-icons {
                justify-content: center;
                align-items: center;
                gap: 10px;
                img {
                  width: 34px !important;
                  height: 34px !important;
                }
                .profile-detail {
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    height: 34px;
                    align-items: center;
                    cursor: pointer;
                    img {
                        width: 22px!important;
                        height: 22px!important;
                    }
                  a {
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    height: 34px;
                    align-items: center;
                    .name {
                      font-style: normal;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 19px;
                      // color: #343434;
                    }
                  }
                }
              }
            
              .noti-icon {
                position: relative;
                .notification-panel {
                    position: absolute;
                    width: 403px;
                    // background-color: #fff;
                    border-radius: 8px;
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
                    padding: 10px;
                    z-index: 100;
                    top: 50px;
                    right: 0;
                    padding: 20px;
                    @media screen and (max-width: 530px) {
                      width: 303px;
                    }
                    @media screen and (max-width: 430px) {
                      right: -102px;
                    }
                
                    .notification-header {
                      border-bottom: 2px solid #e2e8f0;
                      padding: 10px 0px;
                      p {
                        font-family: "Gilroy";
                        font-style: normal;
                        font-weight: bolder;
                        font-size: 16px;
                        line-height: 160%;
                        letter-spacing: 1.2px;
                        margin: 0;
                        padding: 0;
                      }
                      .close-btn {
                        border-radius: 50px;
                        padding: 2px 5px;
                        background: transparent;
                        border: none;
                      }
                    }
                
                    .notification-header-content {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding-bottom: 10px;
                    }
                
                    .tabs {
                      display: flex;
                      gap: 10px;
                    }
                
                    .tab {
                      border: none;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 160%;
                      letter-spacing: 0.2px;
                      flex: none;
                      order: 0;
                      flex-grow: 0;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      padding: 8px 12px;
                      gap: 10px;
                      border-radius: 23px;
                    }
                
                    .tab.active {
                      font-style: normal;
                      font-weight: 900;
                      font-size: 14px;
                      line-height: 160%;
                      letter-spacing: 0.2px;
                      color: #2563eb;
                      flex: none;
                      order: 0;
                      flex-grow: 0;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      padding: 5px 12px;
                      gap: 10px;
                      border-radius: 23px;
                    }
                
                    .notification-content {
                      max-height: 400px;
                      overflow-y: auto;
                      &::-webkit-scrollbar {
                        display: none;
                      }
                      .content {
                        .day {
                          font-style: normal;
                          font-weight: 600;
                          font-size: 12px;
                          line-height: 160%;
                          letter-spacing: 1px;
                          color: #94a3b8;
                        }
                        .notification-item {
                          width: 100%;
                          box-sizing: border-box;
                          border-radius: 12px;
                          padding: 10px 20px;
                          gap: 20px;
                
                          .notification-avatar {
                            img {
                              width: 50px !important;
                              height: 50px !important;
                            }
                          }
                          .details {
                            h3 {
                              font-family: "Gilroy-ExtraBold";
                              font-style: normal;
                              font-weight: 600;
                              font-size: 14px;
                              line-height: 160%;
                              letter-spacing: 0.2px;
                              // color: #282828;
                            }
                            p {
                              font-style: normal;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 160%;
                              color: #94a3b8;
                              margin: 0;
                              padding: 0;
                            }
                          }
                          .time-sec {
                            flex-direction: column;
                            gap: 24px;
                
                            .time {
                              font-style: normal;
                              font-weight: 500;
                              font-size: 10px;
                              line-height: 160%;
                              color: #94a3b8;
                            }
                            .unread-dot {
                              width: 8px;
                              height: 8px;
                              background-color: #3f72af;
                              border-radius: 50%;
                            }
                          }
                        }
                      }
                    }
                    &.dark-mode {
                      background: #18191a;
                      .notification-header p {
                        color: white;
                      }
                      .notification-header button {
                        color: white;
                      }
                      .tab.active {
                        background: #282828;
                      }
                      .tab {
                        background: #404040;
                        color: #94a3b8;
                      }
                      .notification-item {
                        background: #404040;
                        border: 1px solid #3e3e3e;
                        .details h3 {
                          color: white;
                        }
                      }
                    }
                    &.light-mode {
                      background: #ffff;
                      color: black;
                      .notification-header p {
                        color: #282828;
                      }
                      .notification-header button {
                        color: #282828;
                      }
                      .notification-item {
                        background: #f6f8fb;
                        border: 1px solid #f8fafc;
                        .details h3 {
                          color: #282828;
                        }
                      }
                    }
                  }
              }

        }
    }
}
.menu::-webkit-scrollbar {
  display: none;
}

.form-switch .form-check-input:checked  {
  background-color: #FF7606; 
  border-color: #FF7606; 
}

.form-switch .form-check-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 118, 6, 0.5);
}
