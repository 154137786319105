$mainBgColor: #ececec !important;
$secondBgColor: #ffffff !important;

$blackColor1: #212121 !important;
$blackColor2: #1f1f1f !important;
$blackColor3: #0a0f1f !important;
$blackColor4: #3ebf8f !important;
$blackColor5: #def0fc !important;

$primaryColor: #ff7606 !important;

$primaryColorWithAlpha: #f75dbe1a !important;

$borderColor1: #dddfe3 !important;
$borderColor2: #e7e7e8 !important;
$borderColor3: #dddfe3 !important;
$borderColor4: #cbd0dc !important;

$grayTextColor: #9e9e9e !important;
$grayTextColor2: #fcfcfc !important;
$grayTextColor3: #cfcfcf !important;
$grayTextColor4: #abb4ba !important;
$grayTextColor5: #a9acb4 !important;

.black-text {
  color: $blackColor1;
}

.primary-text {
  color: $primaryColor;
}

.gray-text {
  color: $grayTextColor;
}

.pointer {
  cursor: pointer;
}

.link-text {
  @extend .primary-text;

  @extend .pointer;
}

.primary-btn {
  color: white !important;
  background-color: $primaryColor;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.black-btn {
  color: white !important;
  background-color: $blackColor3;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.btn {
  border-radius: 12px;
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  margin: 8px;
  cursor: pointer;
}

// export variables for using in JS files
:export {
  primaryColor: $primaryColor;
  primaryColorWithAlpha: $primaryColorWithAlpha;
  mainBgColor: $mainBgColor;
  secondBgColor: $secondBgColor;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.3s ease, background-image 0.3s ease;
}

.form-switch .form-check-input::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.form-switch .form-check-input::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-color: #ff7606;
  border-color: #ff7606;
  border: 1px solid #ff7606;
}

.form-switch .form-check-input:not(:checked) {
  background-color: #D9D9D9;
  border: 1px solid #D9D9D9;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
  box-shadow: none;
}

.tooltip {
  position: relative;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}


.h-100dvh {
  height: 100dvh !important;
}

.lc-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}