@import "../../../_global.scss";

.Dashboard {
  width: 100%;
  height: fit-content;

  .headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .titleContainer {
      display: flex;
      flex-direction: column;
      margin-right: auto;
      padding-right: 10px;
    }

    .search {
      display: flex;
      flex-direction: row;
      width: 45%;
      height: auto;
      min-height: 50px;
      padding: 5px 10px;

      // background-color: #181829;
      background-color: $secondBgColor;
      border-radius: 50px;
      justify-content: center;
      align-items: center;

      input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        padding: 0 10px;
        color: gray;
        font-size: 16px;
        border: none;
        outline: none;
      }

      .searchButton {
        width: 35px;
        height: 30px;
        border-radius: 100%;

        // background-image: linear-gradient(to right, #B52929, #ED1B1B);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        font-size: 1.3rem;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 20px;
    }
  }
}
