.Card{
    width: 100%;
    padding: 8px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:black;
    padding: 15px;
    border-radius: 15PX;
    background-color: white;
    height: 134px;
    .cardText {
        font-size: 18px;
        color: #7E7E7E;
    }
    .profileImage {
        border-radius: 50%;
        width: 65px;
        height: 65px;
      }
}

.iconCard{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    padding: 10px;
    background-color: white;
    color: black;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.95px solid #E2E2E2
}