.mapContainer {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.5);
    top: 0;

    .background {
        position: absolute;
        background-color: rgb(0, 0, 0, 0.5);
        width: 100%;
        min-height: 100vh;
        z-index: 1;

    }

    .map {
        width: 60%;
        height: 60vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;

        @media (max-width : 1200px) {
            width: 70%;
            height: 70vh;

        }

        .googleMap {
            width: 100%;
            height: 100%;
        }
    }
}