// Variables
$primary-color: #0078d4;
$reject-color: #d9534f;
$accept-color: #28a745;
$white: white !important;
$navyBlue: #2C2C34;
$text-color: white;
$shadow-color: rgba(0, 0, 0, 0.1);

// Popup Bar
.callBar {
    position: fixed;
    bottom: 0; // Or use `top: 0;` for a top bar
    top: 10px;
    right: 10px;
    width: fit-content;
    height: fit-content;
    background-color: $navyBlue;
    box-shadow: 0 -2px 5px $shadow-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    z-index: 1000;
    gap: 40px;
    border-radius: 10px;

    // Caller Info
    &__info {
        display: flex;
        align-items: center;
        gap: 15px;

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $shadow-color;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            color: $primary-color;
        }

        .details {
            display: flex;
            flex-direction: column;

            .callerName {
                font-size: 14px;
                font-weight: bold;
                color: $text-color;
            }

            .status {
                font-size: 12px;
                color: $text-color;
                opacity: 0.7;
            }
        }
    }

    // Actions
    &__actions {
        display: flex;
        gap: 10px;

        .button {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            cursor: pointer;
            color: $white;
            transition: background-color 0.3s ease;

            &Accept {
                background-color: $accept-color;

                &:hover {
                    background-color: darken($accept-color, 10%);
                }
            }

            &Reject {
                background-color: $reject-color;

                &:hover {
                    background-color: darken($reject-color, 10%);
                }
            }
        }
    }
}