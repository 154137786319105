@import "../../../global";
.sideview {
    // height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    overflow: overlay;

    // padding: 0 15px;
    // background-color: #0D0D1C;
    background-color: $secondBgColor;
    transition: 0.3s;
    color: black;
    width: 450px;

    @media (max-width: 991px) {
        position: absolute;
        left: -300px;
        z-index: 10;
    }

    &.collapsed {
        width: 404px;
        left: 0;
    }

    &.notCollapsed {
        width: 90px;
        align-items: center;
    }

    .logo {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 25px 12%;
        transition: 0.3s;

        // min-height: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.notCollapsed {
            padding: 25px 12%;

            .name,
            .profileButton {
                visibility: hidden;
            }
        }

        img {
            // height: 60px;
            // max-height: 80px;
            // max-width: 80px;
            // border-radius: 100%;
            // width: 100%;
            cursor: pointer;
            aspect-ratio: 2;

            &.imageNotCollapsed {
                max-width: 60px;
                max-height: 60px;
            }
        }

        .name {
            width: 100%;
            font-size: 16px;
            margin: 8px 0;
            font-weight: 400;
            display: -webkit-box;
            // -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;

            // white-space: nowrap;
       
        }

        .profileButton {
            padding: 8px 25px;
            white-space: nowrap;
            color: white;
            border-radius: 5px;
            background-color: #181829;
            font-size: 12px;
            border: none;

            &:focus-visible,
            &:focus-within {
                outline: none;
            }
        }

        // @media (max-width : 991px) {
        //     display: none;
        // }
   
    }
    .sideitem {
        padding: 0 15px;
    }

    .dispatchertype {
        overflow-y: scroll;
        height: calc(100vh - 180px);
        width: 100%;
        padding: 0 15px;
        margin-top: 20px;
        scroll-behavior: smooth;
        a {
            text-decoration: none;
        }
    }
    .dispatchertype::-webkit-scrollbar {
        display: none;
    }

    .item {
        width: 100%;
        height: 80px;
        // padding: 18px 24px;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: black;
        font-weight: 500;
        font-size: 20px;

        &.active {
            // background-color: #181829;
            // background-color: $mainBgColor;
            background-color: $primaryColorWithAlpha;

            // border-radius: 15px;
            // color: white;
            color: $primaryColor;
            font-weight: 500;

            // border-right: 5px solid #0AA7FF;
            border-right: 5px solid $primaryColor;
            border-radius: 8px;
        }

        p {
            white-space: nowrap;
            margin: 0px;
        }
    }

    .routeContent {
        position: relative;
        width: 100%;

        background-color: $mainBgColor; // todo:
        display: flex;
        flex-direction: column;
        height: 100dvh;
        overflow: hidden;
        .sideviewContent {
          width: 100%;
          height: -webkit-fill-available;
          display: flex;
          overflow: auto;
        }

        .content {
            width: 100%;
            height: -webkit-fill-available;

            // display: flex;
            overflow: auto;
            padding: 20px;
            
        }
    }
}
.list {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:black;
    border-radius: 15PX;
    background-color: white;
    height: 37px;
    margin: 10px 0px;
    cursor: pointer;
    .cardText {
        font-size: 18px;
        word-wrap: none;
    }
    .profileImage {
        border-radius: 50%;
        width: 35px;
        height: 35px;
      }
    .beatCount {
        font-size: 12px;
        color: #7E7E7E;
        font-weight: 600;
    }
    .fiwi-icon {
        font-size: 14px;
        color: rgb(14, 223, 14);
    }
    .dot {
        width: 11px;
        height: 11px;
        border-radius: 50%;
    }
}

.iconCard{
width: 35px;
height: 35px;
border-radius: 50%;
padding: 10px;
background-color: white;
color: black;
font-size: 2rem;
display: flex;
align-items: center;
justify-content: center;
border: 0.95px solid #E2E2E2
}