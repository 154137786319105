@import "../../../_global.scss";

.vitalsModal {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 900px;
  position: relative;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  &.fit {
    width: fit-content;
  }
  &.full {
    width: 100%;
  }

  .vitalleft {
    //   border-right: 1px solid #ECECEC;
    border-color: #ececec;
    margin-right: 10px;
    @media screen and (max-width: 767px) {
      border-right: none;
      margin-right: 0px;
    }
  }

  .header {
    margin-top: -70px;
    text-align: center;
    .profileImage {
      border-radius: 50%;
      width: 80px;
      height: 80px;
    }

    .userInfo {
      h5 {
        margin: 0;
        font-weight: bold;
      }

      p {
        margin: 2px 0;
        color: #7e7e7e;
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 5px;
    }
  }
  .xmark {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $mainBgColor;
    position: absolute;
    right: 30px;
    top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    display: flex;
    margin-top: 20px;
    //   border-left: 1px solid $mainBgColor;

    .infoSection {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }

    .infoRow {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
        text-align: left;
        width: 50%;
        font-weight: bold;
        color: #7e7e7e;
      }

      strong {
        font-weight: bold;
      }

      .highLighted {
        color: red;
      }

      .waiting {
        color: orange;
      }

      .connected {
        color: green;
      }
    }

    .vitalsGraph {
      display: flex;
      flex-direction: column;
      gap: 30px;
      background-color: $mainBgColor;
      margin-top: 20px;

      .progressBar {
        margin-top: 10px;
        height: 10px;
        background-color: #e0e0e0;
      }

      .vitalsImage,
      .mapImage {
        margin-top: 10px;
        border-radius: 10px;
        width: 100%;
      }
    }
    .graphDiv {
      background-color: #e0e0e0;
      border-radius: 15px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-right: 10px;
    gap: 20px;

    .officerOk {
      padding: 5px 12px;
      width: 50%;
      background-color: black;
      border: none;
      border-radius: 50px;
      color: white;
      p {
        font-size: 14px;
        word-wrap: normal;
        margin: 0px;
      }
      &:hover {
        background-color: #333;
      }
    }

    .assistNow {
      padding: 5px 12px;
      width: 50%;
      background-color: #e22a2a;
      border-radius: 50px;
      border: none;
      color: white;
      p {
        font-size: 14px;
        word-wrap: normal;
        margin: 0px;
      }
      &:hover {
        background-color: red;
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
    min-width: 300px;
    overflow-y: auto;
    height: 70vh;
  }
}

.HeatMap {
  position: relative;
  .xmark {
      background-color: $mainBgColor;
      text-align: right;
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 100000;
    }
}