@import "../../../_global.scss";
.HeatMap {
    position: relative;
    .xmark {
        background-color: $mainBgColor;
        text-align: right;
        position: absolute;
        right: 20px;
        top: 20px;
      }
}
