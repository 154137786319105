@import "../../../global";
.chat {
    height: 100%;

        .colChat {
            height: 78vh;

            .userChat {
                height: 100%;

                .backIcon {
                    .chats {
                        font-family: "Gilroy";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 14px;
                        text-align: center;
                        color: #000000;
                    }
                }

                .chatContainer {
                    box-sizing: border-box;
                    border: 0.599746px solid #A7A7A7;
                    filter: drop-shadow(14.3939px 4.79797px 64.3153px rgba(0, 0, 0, 0.05));
                    border-radius: 15px;
                    // transform: matrix(-1, 0, 0, 1, 0, 0);
                    height: 95%;
                    overflow: hidden;

                    .userInfoSec {
                        padding: 15px 30px;

                        p {
                            font-family: "Gilroy-ExtraBold" !important;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 22px;
                            color: #323232;
                        }
                    }

                    .chatsList {
                        height: 100%;
                        // height: calc(100% - 70px);
                        background: #ebebeb;
                        border-radius: 12px 12px 20px 20px;
                        padding: 10px;
                        

                        .list {
                            height: calc(100% - 120px);
                            // height: 100%;
                            overflow-y: auto;
                            overflow-x: hidden;
                            padding: 20px;
                        }

                        .userRecieveSec {
                            .userName {
                                font-family: 'Gilroy';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 11px;
                                line-height: 9px;
                                color: #323232;
                            }

                            .message {
                                p {
                                    width: 303.26px;
                                    height: auto;
                                    background: #FFFFFF;
                                    border-radius: 15px 15px 15px 4px;
                                    padding: 10px;
                                    font-family: 'Gilroy';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 12px;
                                    color: #323232;

                                    @media screen and (max-width:500px) {
                                        width: 250px;
                                    }

                                }

                                span {
                                    font-family: 'Gilroy';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 8px;
                                    line-height: 9px;
                                    color: #323232;
                                }
                            }
                        }

                        .userSenderSec {
                            float: right;
                        }

                        .userSenderSec {
                            .userName {
                                font-family: 'Gilroy';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 11px;
                                line-height: 9px;
                                color: #323232;
                            }

                            .message {
                                p {
                                    width: 303.26px;
                                    height: auto;
                                    background: #013569;
                                    padding: 10px;
                                    border-radius: 15px 15px 4px 15px;
                                    font-family: 'Gilroy';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 12px;
                                    // color: $color-white;

                                    @media screen and (max-width:500px) {
                                        width: 250px;
                                    }
                                }

                                span {
                                    font-family: 'Gilroy';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 8px;
                                    line-height: 9px;
                                    color: #323232;
                                }
                            }
                        }

                        .formInput {
                            position: relative;

                            .formControl {
                                height: 50px;
                                background: #FCFCFC;
                                border-radius: 18px;
                                padding: 0px 50px !important;
                                position: relative;
                                width: 100%;
                                outline: none;
                                border: none;

                                &::placeholder {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 23px;
                                    color: #58677d;
                                    opacity: 0.5;

                                    @media screen and (max-width: 460px) {
                                        font-size: 12px !important;
                                    }
                                }
                            }

                            .emoji {
                                position: absolute;
                                top: 10px;
                                left: 10px;
                            }

                            .attachment {
                                position: absolute;
                                top: 10px;
                                right: 78px;

                                @media screen and (max-width:500px) {
                                    top: 14px;
                                    right: 62px;
                                }

                                svg {
                                    @media screen and (max-width: 500px) {
                                        width: 15px;
                                        height: 15px;
                                    }
                                }
                            }

                            button {
                                // height: 50px;
                                background: $primaryColor;
                                // border-radius: 50px;
                                // padding: 0px 10px;
                                // color: #ffffff;
                                // font-size: 15px;
                                // font-weight: 600;
                                border: none;
                            }

                            .selected-file {
                                background-color: #013569;
                                color: #ffffff;
                                font-size: 15px;
                                font-weight: 600;
                                position: absolute;
                                padding: 8px 12px;
                                bottom: 56px;
                                border-radius: 9px;
                            }
                        }
                    }
                }
            }
        }

        .colUserList {
            height: 78vh;

            .chatListContainer {
                box-sizing: border-box;
                border: 0.599746px solid #A7A7A7;
                filter: drop-shadow(14.3939px 4.79797px 64.3153px rgba(0, 0, 0, 0.05));
                border-radius: 15px;
                // transform: matrix(-1, 0, 0, 1, 0, 0);
                height: 100%;
                overflow: hidden;
                padding: 20px;

                .topContent {
                    h1 {
                        font-family: "Gilroy-ExtraBold" !important;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 19px;
                        line-height: 160%;
                        color: #282828;
                    }

                    .searchInput {
                        position: relative;

                        .formControl {
                            width: 100%;
                            height: 48px;
                            background: rgba(0, 0, 0, 0.06);
                            font-size: 15px;
                            color: #000000;
                            letter-spacing: 1px;
                            padding-left: 40px !important;
                            border-radius: 18px;
                            outline: none;
                            border: none;

                            &::placeholder {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 15px;
                                line-height: 23px;
                                color: #58677d;
                                opacity: 0.5;

                                @media screen and (max-width: 460px) {
                                    font-size: 12px !important;
                                }
                            }
                        }

                        svg {
                            position: absolute;
                            top: 14px;
                            left: 13px;
                            width: 20.5px;
                            height: 20.5px;
                        }
                    }
                }

                .list {
                    height: calc(100% - 100px);
                    overflow: auto;
                    // @include scroll-hidden;

                    .users {
                        .userInfo {
                            .name {
                                p {
                                    padding: 0;
                                    margin: 0;
                                    font-family: "Gilroy-ExtraBold" !important;
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 12.4174px;
                                    line-height: 120%;
                                    color: #121212;
                                }

                                .message {
                                    margin: 0;
                                    padding: 0;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: #353535;
                                }
                            }
                        }

                        .time {
                            gap: 5px;

                            p {
                                margin: 0;
                                padding: 0;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 8.14px;
                                line-height: 9px;
                                color: #565656;
                            }

                            .numbers {
                                width: 17.71px;
                                height: 17.71px;
                                background: #013569;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 7.14027px;
                                line-height: 11px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                letter-spacing: 0.142805px;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }

}