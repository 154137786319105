@import "../../../_global.scss";

.modalContainer {
  padding: 20px;
  width: 100%;
  max-width: 500px;
  height: fit-content;

  /* max-height: 500px; */
  max-height: 80vh;
  border-radius: 18px;

  /* background-color: #0D0D1C; */
  background-color: $secondBgColor;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1000;
  overflow: auto;

  .xmark {
    background-color: $mainBgColor;
    text-align: right;
  }

  .button {
    background-color: $primaryColor;
  }
}

hr {
  border-top: 1px solid #48556f !important;
  width: 90%;
}

input::-ms-reveal {
  filter: invert(100%);
}

@media only screen and (max-width: 768px) {
  .modalContainer {
    max-width: 90vw;
  }
}

.vitalsModal {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 900px;
  position: relative;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;

  .vitalleft {
    width: 300px;
    border-right: 1px solid #ECECEC;
    margin-right: 10px;
    @media screen and (max-width: 767px) {
        border-right: none;
        margin-right: 0px;
    }
  }

  .header {
    margin-top: -70px;
    text-align: center;
    .profileImage {
      border-radius: 50%;
      width: 80px;
      height: 80px;
    }

    .userInfo {
      h5 {
        margin: 0;
        font-weight: bold;
      }

      p {
        margin: 2px 0;
        color: #7E7E7E;
      }
    }
    @media screen and (max-width: 768px) {
        margin-top: 5px;
    }
  }
  .xmark {
    background-color: $mainBgColor;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
  }

  .content {
    display: flex;
    margin-top: 20px;
    // border-left: 1px solid $mainBgColor;

    .infoSection {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }

    .infoRow {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
        text-align: left;
        width: 50%;
        font-weight: bold;
        color: #7E7E7E;
      }

      strong {
        font-weight: bold;
      }

      .highLighted {
        color: red;
      }

      .waiting {
        color: orange;
      }

      .connected {
        color: green;
      }
    }

    .vitalsGraph {
      display: flex;
      flex-direction: column;
      gap: 30px;
      background-color: $mainBgColor;

      .progressBar {
        margin-top: 10px;
        height: 10px;
        background-color: #e0e0e0;
      }

      .vitalsImage,
      .mapImage {
        margin-top: 10px;
        border-radius: 10px;
        width: 100%;
      }
    }
    .graphDiv {
      background-color: #e0e0e0;
      border-radius: 15px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-right: 10px;
    gap: 20px;

    .officerOk {
      padding: 5px 12px;
      width: 50%;
      background-color: black;
      border: none;
      border-radius: 50px;
      color: white;
      p {
        font-size: 14px;
        word-wrap: normal;
        margin: 0px;
      }
      &:hover {
        background-color: #333;
      }
    }

    .assistNow {
      padding: 5px 12px;
      width: 50%;
      background-color: #e22a2a;
      border-radius: 50px;
      border: none;
      color: white;
      p {
        font-size: 14px;
        word-wrap: normal;
        margin: 0px;
      }
      &:hover {
        background-color: red;
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
    min-width: 300px;
    overflow-y: auto;
    height: 70vh;
  }
}

.Menu {
  width: 100%;
  height: 100%;
  overflow: auto;

  .option {
      padding: 5px 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 18px;
      margin: 0 auto ;
      justify-content: space-between;

      // background-color: #0D0D1C;
      background-color: $secondBgColor;
      color: white;
      cursor: pointer;

      span.icon {
          display: flex;
          font-size: .8rem;
          margin-right: 15px;
      }
  }

  .active {
      border-color: $primaryColor;
      border-style: solid;
      border-width: 1px;
  }
}