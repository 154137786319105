.booking {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    .titleContainer {
        font-family: 'Roboto';
        font-size: 48px;
        font-weight: 400;
        line-height: 56.25px;
        text-align: left;
    }
    .cossf {
        width: 186px;
        height: 184px;
    }
}